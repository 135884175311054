填报表单
<template>
  <v-container
    id="regular-tables"
    tag="section"
    fluid
  >
    <v-card class="mb-10">
      <v-breadcrumbs :items="breadcrumbs" />
    </v-card>

    <Form
      ref="formValidate"
      label-position="top"
      :model="formValidate"
      :rules="ruleValidate"
    >
      <v-card
        class="pa-6"
        outlined
        width="1400px"
        style="margin:0 auto;max-width:1400px"
      >
        <v-tabs
          vertical
          color="#fff"
          style="width:1360px"
        >
          <v-tab
            active-class="tabActive"
            class="tabcla"
          >
            项目基础信息
          </v-tab>
          <v-tab
            active-class="tabActive"
            class="tabcla"
          >
            项目对接信息
          </v-tab>
          <v-tab-item
            transition="slide-y-transition"
            class="ml-4"
          >
            <v-card
              outlined
              width="350px"
              style="float:left"
              class="mt-1 ml-6 mr-6 pa-4"
            >
              <v-toolbar-title class="mb-6">
                合作项目情况
              </v-toolbar-title>
              <FormItem
                label="合作项目名称"
                prop="name"
              >
                <Input
                  v-model="formValidate.name"
                  clearable
                  placeholder="合作项目名称"
                />
              </FormItem>
              <FormItem
                label="该合作项目所属产业类别"
                prop="industry"
              >
                <Select
                  v-model="formValidate.industry"
                  clearable
                  placeholder="该合作项目所属产业类别"
                >
                  <Option
                    v-for="(item,inx) in industrys"
                    :key="inx"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </Option>
                </Select>
              </FormItem>
              <FormItem
                label="制造业合作项目"
                prop="isManufacturingProject"
              >
                <RadioGroup v-model="formValidate.isManufacturingProject">
                  <Radio
                    v-for="(item,inx) in isProposals"
                    :key="inx"
                    :label="item.value"
                  >
                    {{ item.label }}
                  </Radio>
                </RadioGroup>
              </FormItem>

              <FormItem
                label="投资规模（万美元）"
                prop="investmentScale"
              >
                <Input
                  v-model="formValidate.investmentScale"
                  clearable
                  placeholder="投资规模（万美元）"
                  type="number"
                />
              </FormItem>

              <FormItem
                label="合作方式"
                prop="cooperationMode"
              >
                <Select
                  v-model="formValidate.cooperationMode"
                  clearable
                  placeholder="合作方式"
                >
                  <Option
                    v-for="(item,inx) in cooperationModes"
                    :key="inx"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </Option>
                </Select>
              </FormItem>
            </v-card>
            <v-card
              outlined
              width="350px"
              style="float:left"
              class="mt-1 ml-6 mr-6 pa-4"
            >
              <v-toolbar-title class="mb-6">
                投资公司
              </v-toolbar-title>
              <FormItem
                label="公司名称"
                prop="projectName"
              >
                <Input
                  v-model="formValidate.projectName"
                  clearable
                  placeholder="请填写母公司名称"
                />
              </FormItem>

              <FormItem
                label="国别（地区）"
                prop="country"
              >
                <Cascader
                  v-model="formValidate.country"
                  clearable
                  :data="countrys"
                />
              </FormItem>
              <FormItem
                label="公司主营业务所属产业"
                prop="industryCategory"
              >
                <Select
                  v-model="formValidate.industryCategory"
                  multiple
                  placeholder="公司主营业务所属产业"
                >
                  <Option
                    v-for="(item,inx) in industrys"
                    :key="inx"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </Option>
                </Select>
              </FormItem>
            </v-card>
            <v-card
              outlined
              width="350px"
              style="float:left"
              class="mt-1 ml-6 mr-6 pa-4"
            >
              <v-toolbar-title class="mb-6">
                投资方沟通渠道
              </v-toolbar-title>

              <FormItem
                label="联系人"
                prop="businessContacts"
              >
                <Input
                  v-model="formValidate.businessContacts"
                  clearable
                  placeholder="联系人"
                  type="tel"
                />
              </FormItem>
              <FormItem
                label="联系电话"
                prop="businessContactsTel"
              >
                <Input
                  v-model="formValidate.businessContactsTel"
                  clearable
                  placeholder="联系电话"
                  type="tel"
                />
              </FormItem>
              <FormItem
                label="电子邮件"
                prop="email"
              >
                <Input
                  v-model="formValidate.email"
                  clearable
                  placeholder="电子邮件"
                />
              </FormItem>
              <FormItem
                label="网址"
                prop="web"
              >
                <Input
                  v-model="formValidate.web"
                  clearable
                  placeholder="网址"
                />
              </FormItem>
            </v-card>

            <v-card
              width="1150px"
              flat
              style="float:left"
              class="mt-1 ml-6 mr-6"
            >
              <FormItem
                label="公司简介"
                prop="companyProfile"
              >
                <Input
                  v-model="formValidate.companyProfile"
                  clearable
                  type="textarea"
                  :rows="5"
                  placeholder="请填写公司基本情况、主营业务、行业地位等信息"
                />
              </FormItem>
              <FormItem
                label="合作意向具体内容"
                prop="content"
              >
                <Input
                  v-model="formValidate.content"
                  clearable
                  type="textarea"
                  :rows="5"
                  placeholder="合作意向具体内容"
                />
              </FormItem>
              <FormItem
                label="投资条件（要素需求或其他）"
                prop="investmentConditions"
              >
                <Input
                  v-model="formValidate.investmentConditions"
                  clearable
                  type="textarea"
                  :rows="5"
                  placeholder="投资条件（要素需求或其他）"
                />
              </FormItem>
            </v-card>
          </v-tab-item>

          <v-tab-item
            transition="slide-y-transition"
            class="ml-4"
          >
            <v-card
              outlined
              width="350px"
              style="float:left"
              class="mt-1 ml-6 mr-6 pa-4"
            >
              <v-toolbar-title class="mb-6">
                项目信息对接情况
              </v-toolbar-title>

              <FormItem
                label="项目信息引荐人"
                prop="contact"
              >
                <Input
                  v-model="formValidate.contact"
                  clearable
                  placeholder="项目信息引荐人"
                />
              </FormItem>
              <FormItem
                label="项目信息介绍部门"
                prop="ProjectDepartmentInformation"
              >
                <Input
                  v-model="formValidate.ProjectDepartmentInformation"
                  clearable
                  placeholder="项目信息介绍部门"
                />
              </FormItem>
              <FormItem
                label="介绍人电话"
                prop="phone"
              >
                <Input
                  v-model="formValidate.phone"
                  clearable
                  placeholder="介绍人电话"
                  type="tel"
                />
              </FormItem>
              <FormItem
                label="介绍人电子邮件"
                prop="projectEmail"
              >
                <Input
                  v-model="formValidate.projectEmail"
                  clearable
                  placeholder="介绍人电子邮件"
                />
              </FormItem>
            </v-card>
            <v-card
              outlined
              width="350px"
              style="float:left"
              class="mt-1 ml-6 mr-6 pa-4"
            >
              <v-toolbar-title class="mb-6">
                项目状态
              </v-toolbar-title>
              <FormItem
                label="信息提供部门是否有推送建议"
                prop="isProposal"
              >
                <RadioGroup v-model="formValidate.isProposal">
                  <Radio
                    v-for="(item,inx) in isProposals"
                    :key="inx"
                    :label="item.value"
                  >
                    {{ item.label }}
                  </Radio>
                </RadioGroup>
              </FormItem>

              <FormItem
                v-show="formValidate.isProposal ==='是'"
                label="推送建议"
                prop="pushReason"
              >
                <Input
                  v-model="formValidate.pushReason"
                  type="textarea"
                  :rows="5"
                  clearable
                  placeholder="推送建议"
                />
              </FormItem>
            </v-card>
            <v-card
              width="1150px"
              flat
              style="float:left"
              class="mt-1 ml-6 mr-6"
            >
              <FormItem
                label="项目投资方重点关注事项"
                prop="KeyConcerns"
              >
                <Input
                  v-model="formValidate.KeyConcerns"
                  clearable
                  type="textarea"
                  :rows="5"
                  placeholder="项目投资方重点关注事项"
                />
              </FormItem>
            </v-card>
          </v-tab-item>
        </v-tabs>
        <v-btn
          color="green"
          @click="handleSubmit('formValidate')"
        >
          提交
        </v-btn>
      </v-card>
    </Form>
  </v-container>
</template>
<script>
  import _ from 'lodash'
  import userPhoto from '../../assets/user_photo.jpg'
  import mapIcon from '../../assets/map_icon.png'
  export default {
    components: {
      // Upload: Upload,
    },
    data: () => ({
      formValidate: {
        contact: '',
        ProjectDepartmentInformation: '',
        phone: '',
        email: '',
        isProposal: '',
        pushReason: '',
        KeyConcerns: '',
        name: '',
        industryCategory: [],
        isManufacturingProject: '',
        investmentScale: '',
        cooperationMode: '',
        projectName: '',
        industry: '',
        country: [],
        businessContacts: '',
        businessContactsTel: '',
        projectEmail: '',
        web: '',
        companyProfile: '',
        content: '',
        investmentConditions: '',
      },
      ruleValidate: {
        contact: [
          { required: true, message: '请填写项目信息引荐人', trigger: 'blur' },
        ],
        ProjectDepartmentInformation: [
          { required: true, message: '请填写项目信息介绍部门', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请填写介绍人电话', trigger: 'blur' },
        ],
        email: [
          { type: 'email', message: '电子邮箱规则不正确', trigger: 'blur' },
        ],
        projectEmail: [
          { type: 'email', message: '电子邮箱规则不正确', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请填写合作项目名称', trigger: 'blur' },
        ],
        industryCategory: [
          { required: true, type: 'array', min: 1, message: '请填写公司主营业务所属产业', trigger: 'change' },
        ],
        country: [
          { required: true, type: 'array', min: 1, message: '请填写该国别（地区）', trigger: 'change' },
          { type: 'array', max: 999, message: '请填写该国别（地区）', trigger: 'change' },
        ],
        isManufacturingProject: [
          { required: true, message: '请填写制造业合作项目', trigger: 'change' },
        ],
        cooperationMode: [
          { required: true, message: '请填写合作方式', trigger: 'change' },
        ],
        projectName: [
          { required: true, message: '请填写母公司名称', trigger: 'blur' },
        ],
        industry: [
          { required: true, message: '请填写公司主营业务所属产业', trigger: 'change' },
        ],
        companyProfile: [
          { required: true, message: '请填写公司简介', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '请填写合作意向具体内容', trigger: 'blur' },
        ],
        investmentConditions: [
          { required: true, message: '请填写投资条件（要素需求或其他）', trigger: 'blur' },
        ],
      },
      industrys: [],
      isProposals: [],
      cooperationModes: [],
      countrys: [],
      uploadList: [],
      mapIcon: mapIcon,
      userPhoto: userPhoto,
      formLoading: true, // 表单加载圈
      // 项目阶段选择下拉菜单数据源
      stages: [],
      formId: null,
      breadcrumbs: [ // 面包屑导航
        {
          text: '首页',
          disabled: false,
        },
        {
          text: '编辑',
          disabled: true,
        },
      ],
      formCreateRule: { // 表单创建规则
        // 实例对象
        fApi: {},
        // 表单生成规则
        rule: [],
        // 组件参数配置
        option: {
          submitBtn: false,
          form: {
            labelWidth: undefined,
            labelPosition: 'top',
          },
          row: {
            gutter: 30,
          },
        },
      },
      project: null,
      projectId: null,
      formAreaCode: '',
      T: null,
      map: null,
      lnglat: [],
      isAdmin: false,
    }),
    watch: {
      // 监听路由是否变化，一旦变化重新设置表单id和拉取项目列表
      $route: {
        handler (to, from) {
          // this.createForm(this.formId, this.projectId)
          console.log('有变化')
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    async created () {
      this.isAdmin = localStorage.isAdmin === 'true' || localStorage.isAdmin === true
      // 如果有表单ID，就获取赋值
      if (this.$route.query.formId) {
        this.formId = this.$route.query.formId
        if (JSON.parse(localStorage.newForms).length) {
          const formTypes = JSON.parse(localStorage.newForms)[0].children
          formTypes.map(val => {
            if (val.id === this.formId) {
              this.breadcrumbs[1].text = val.name + '编辑'
            }
          })
        }
      }
      // 如果有项目ID，就获取赋值
      if (this.$route.query.id) {
        this.projectId = this.$route.query.id
        const formData = await this.inputValue(this.projectId) // 获取项目数据
        Object.keys(formData).map(val => {
          Object.keys(this.formValidate).map(valp => {
            if (val === valp) {
              if (val === 'country' || val === 'industryCategory') {
                console.log('formData[val]', formData[val]);

                this.formValidate[valp] = formData[val].split(',')
              } else {
                this.formValidate[valp] = formData[val]
              }
            }
          })
        })
      }
    },
    async mounted () {
      this.industrys = await this.getSelectDictData('08be7c1a-f3c7-4ef3-aef6-a2f56eb82531')
      this.isProposals = await this.getSelectDictData('yesOrNo')
      this.cooperationModes = await this.getSelectDictData('a020d617-8397-4546-b824-511605ac3b1f')
      this.countrys = await this.getTreeDictData('country', '0')
    },
    methods: {
      handleSubmit:
        _.throttle(function (name) {
          console.log('this.formValidate', this.formValidate);

          this.$refs[name].validate((valid) => {
            if (valid) {
              this.submitData(this.formId, this.projectId)
            } else {
              this.$Message.error('请按照提示填写!');
            }
          })
        }, 1500, { trailing: false }),
      // 获取选择列表数据
      async getSelectDictData (dictId) {
        const select = []
        await this.$axios.get(`/dictionaries/${dictId}`)
          .then(res => {
            const resData = res.data.data
            resData.map(val => {
              if (val.name === '吕涛书记重点调度项目') {
                select.push({
                  value: val.code,
                  label: val.name,
                })
              } else {
                select.push({
                  value: val.code,
                  label: val.name,
                })
              }
            })
          })
        return select
      },
      // 获取树形数据1
      async getTreeDictData (dictId, cityId, type) {
        let tree = []
        if (dictId) {
          await this.$axios.get(`/dictionaries/${dictId}`)
            .then(res => {
              const resData = res.data.data
              resData.map(val => {
                val.cid = val.id
                // 级联菜单
                val.value = val.code
                val.label = val.name
                // 树状菜单
                val.title = val.name
                val.id = val.code
              })
              if (type === 'inferred' || type === 'pushCity') {
                tree = this.treeData(resData, cityId, 'inferred')
                console.log('tree', tree);
              } else {
                tree = this.treeData(resData, cityId)
              }
            })
        }
        return tree
      },
      // 格式化用户组数据返回树形结构
      treeData (sourceData, id, type) {
        const cloneData = JSON.parse(JSON.stringify(sourceData))
        return cloneData.filter(father => {
          if (type) {
            // 园区市级不能选
            // if (father.parentId === 0) {
            //   father.disabled = true
            // }

            const branchArr = cloneData.filter(child => father.cid === child.parentId)
            // branchArr.length > 0 ? father.children = branchArr : father.children = []
            return father.parentId + '' === id + ''
          } else {
            const branchArr = cloneData.filter(child => father.cid === child.parentId)
            branchArr.length > 0 ? father.children = branchArr : father.children = []
            return father.parentId + '' === id + ''
          }
        })
      },
      // 获取项目信息
      async inputValue (projectId) {
        // 判断是否有项目ID，新增项目是没有项目ID的
        if (projectId) {
          const projectInfo = await this.$axios.get(`/reports/${projectId}/detail`)
          return projectInfo.data.data.data
        } else {
          return null
        }
      },
      // 保存按钮
      saveBtn:
        _.throttle(function () {
          this.formCreateRule.fApi.validate((valid) => {
            if (valid) {
              this.saveData(this.formId, this.projectId)
            } else {
              this.$swal({
                title: '表单验证未通过',
                text: '请查看红色表单项与提示',
                icon: 'warning',
                confirmButtonText: '知道了',
              })
            }
          })
        }, 1500, { trailing: false }),
      // 提交按钮
      submitBtn:
        _.throttle(function () {
          this.formCreateRule.fApi.validate((valid) => {
            if (valid) {
              this.submitData(this.formId, this.projectId)
            } else {
              this.$swal({
                title: '表单验证未通过',
                text: '请查看红色表单项与提示',
                icon: 'warning',
                confirmButtonText: '知道了',
              })
            }
          })
        }, 1500, { trailing: false }),
      // 格式化表单数据
      async filterFormData () {
        const ysFormData = JSON.parse(JSON.stringify(this.formValidate))
        for (const data in ysFormData) {
          if (typeof ysFormData[data] === 'string') {
            if (ysFormData[data] === '') {
              ysFormData[data] = null
            }
          } else if (ysFormData[data] instanceof Array) {
            console.log('ysFormData[data]', ysFormData[data]);

            if (ysFormData[data].length === 0) {
              ysFormData[data] = null
            } else if (ysFormData[data][0] === '') {
              ysFormData[data] = null
            } else {
              ysFormData[data] = ysFormData[data].toString()
            }
          }
        }

        return ysFormData
      },
      // 保存表单数据
      async saveData (formId, id) {
        let requert = null
        const filterFormData = await this.filterFormData()
        if (formId === '9632d127-2e30-4945-aeb6-e7074b701c1a') {
          const res = await this.$axios.get('/reports/' + filterFormData.parentID + '/detail')
          console.log(res, filterFormData)
          const data = res.data.data.data
          filterFormData.parentName = data.name
          filterFormData.areaCode = data.areaCode
        }
        const weight = filterFormData.weight ? filterFormData.weight : 0
        if (!id) {
          if (localStorage.userName) {
            filterFormData.fillingUser = localStorage.userName
          }
          requert = this.$axios.post(`/forms/${formId}/reports3`, {
            data: filterFormData,
            status: 0,
            weight: weight,
          })
        } else {
          requert = this.$axios.put(`/reports/${id}/update3`, {
            data: filterFormData,
            weight: weight,
          })
        }
        requert.then((res) => {
          this.$swal({
            title: '保存成功!',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          })
          this.$router.go(-1)
        })
          .catch((err) => {
            console.log(err)
            this.$swal({
              title: '保存失败!',
              icon: 'error',
            })
          })
      },
      // 提交表单数据
      async submitData (formId, id) {
        let requert = null
        const filterFormData = await this.filterFormData()
        const weight = filterFormData.weight ? filterFormData.weight : 0
        console.log('filterFormData', filterFormData);

        if (!id) {
          if (localStorage.userName) {
            filterFormData.fillingUser = localStorage.userName
          }
          requert = this.$axios.post(`/forms/${formId}/reports3`, {
            data: filterFormData,
            status: 0,
            weight: weight,
          })
        } else {
          requert = this.$axios.put(`/reports/${id}/update3`, {
            data: filterFormData,
            weight: weight,
          })
        }
        requert.then((res) => {
          let projectId = null
          if (id) {
            projectId = id
          } else {
            projectId = res.data.data.id
          }
          this.$axios.put(`/reports/${projectId}/submit`)
            .then(res => {
              this.$swal({
                title: '提交成功!',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.go(-1)
            })
            .catch((err) => {
              console.log(err)
              this.$swal({
                title: '提交失败!',
                icon: 'error',
              })
            })
        })
          .catch((err) => {
            console.log(err)
            this.$swal({
              title: '提交失败!',
              icon: 'error',
            })
          })
      },
    },
  }
</script>
<style lang='scss' scoped>
.ivu-date-picker {
  display: block;
}
::v-deep .ivu-input-number {
  width: 100%;
}
.mapDiv {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 500px;
  touch-action: pan-y;
}
.mapEditBox {
  text-align: center;
}
/* #regular-tables{
  position: relative;
}; */
/* #mapBox{
  position: absolute;
  left: -9999px;
} */
.amap-icon img,
.amap-marker-content img {
  width: 25px;
  height: 34px;
}

.marker {
  position: absolute;
  top: -20px;
  right: -118px;
  color: #fff;
  padding: 4px 10px;
  box-shadow: 1px 1px 1px rgba(10, 10, 10, 0.2);
  white-space: nowrap;
  font-size: 12px;
  font-family: '';
  background-color: #25a5f7;
  border-radius: 3px;
}

.input-card {
  width: 18rem;
  z-index: 170;
}

.input-card .btn {
  margin-right: 0.8rem;
}

.input-card .btn:last-child {
  margin-right: 0;
}
::v-deep .tabActive{
 background-color: #0D47A1 !important;
 border:1px solid #0D47A1 !important;
}
.tabcla{
 border-radius: 10px;
 background-color: #E9E9E9;
 font-size:16px;
 font-weight:600;
 margin-bottom: 20px;
 border:1px solid #B4B4B4;
 }
 ::v-deep .v-tabs-slider-wrapper{
 display: none;
 }
</style>
